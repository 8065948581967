<template>
  <div>
    <Categories />
  </div>
</template>
<script>
  import Categories from "@/components/MerchantCategories";
  export default {
    components: {
      Categories,
    },
  };
</script>
